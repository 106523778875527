import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="psionic-powers-quintessence-to-weapon-of-energy"></a><h2>Psionic Powers</h2>
    <h2 style={{
      "marginTop": "-40px",
      "marginBottom": "0px"
    }}><span className="subtitle">Quintessence&mdash;Weapon
of
Energy</span></h2>
    <h6><a id="quintessence"></a>Quintessence</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Metacreativity (Creation)</span>
    <b>Level:</b> Shaper 4
    <b>Display:</b> Material; see text
    <b>Manifesting Time:</b> 1 round
    <b>Range:</b> 0 ft.
    <b>Effect:</b> 1-inch-diameter dollop of quintessence; see text
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> None
    <b>Power Resistance:</b> No
    <b>Power Points:</b> 7
    <p>You collapse a bit of time from the continuum, forming a
1-ounce dollop
of thick, gooey material called quintessence. This substance shimmers
like a silver mirror when viewed from some angles but is transparent
from other viewpoints. You can smooth a dollop of quintessence around
any extremely small object.</p>
    <p>Objects sealed within quintessence are protected from the
effects of
time; in practical terms, they enter a state of stasis. Living flesh
with only partial contact with quintessence is also partially pulled
out of the time stream (the manifester is immune to this effect). This
disruption deals 1 point of damage per round beginning 10 rounds after
partial contact occurs.</p>
    <p>Quintessence can be manually scraped away from a protected
object,
freeing it to rejoin the time stream. When you do this, there is a 75%
chance that the quintessence evaporates back into the continuum.
Otherwise, it coalesces again into a 1-inch-diameter bead, available
for later use.</p>
    <p>Large quantities of quintessence could theoretically be
gathered to
preserve large items or structures (or even a complete living creature;
if completely immersed, a living creature would not take the damage
associated with partial contact). However, psionic characters and
creatures are generally loath to do so because accumulations of
quintessence weighing 1 pound or more hinder psionic activity within a
5-foot radius of the accumulation: Powers require twice as many power
points to manifest, unless the manifester makes a successful DC 16 Will
save
each time he or she attempts to manifest a power. Also in these
circumstances, manifesting a psi-like ability that is usable at will is
a full-round action rather than a standard action.</p>
    <h6><a id="read-thoughts"></a>Read Thoughts</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <b>Level:</b> Telepath 2
    <b>Display:</b> Mental
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> 60 ft.
    <b>Area:</b> Cone-shaped emanation centered on you
    <b>Duration:</b> Concentration, up to 1 min./level (D)
    <b>Saving Throw:</b> Will negates; see text
    <b>Power Resistance:</b> No
    <b>Power Points:</b> 3
    <p>You know the surface thoughts of the mind of any creature in
the area
that fails a Will save. A target that succeeds on its save is not
affected by this manifestation of the power, even if it leaves the area
and then reenters the area before the duration expires.</p>
    <p>Creatures of animal intelligence have simple, instinctual
thoughts that
you can pick up. If you read the thoughts of a creature with an
Intelligence of 26 or higher (and at least 10 points higher than your
own Intelligence score), you are <a href="abilitiesAndConditions.html#stunned" style={{
        "color": "rgb(87, 158, 182)"
      }}>stunned</a> for 1 round and the
power
ends. This power does not let you pinpoint the location of an affected
mind if you don&rsquo;t have line of sight to the subject.</p>
    <p>Each round, you can turn to use this power in a new area. The
power can
penetrate barriers, but 1 foot of stone, 1 inch of common metal, a thin
sheet of lead, or 3 feet of wood or dirt blocks it.</p>
    <h6><a id="reality-revision"></a>Reality Revision</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience</span>
    <b>Level:</b> Psion/wilder 9
    <b>Saving Throw:</b> See text
    <b>Power Resistance:</b> See text
    <b>Power Points:</b> 17, XP
    <p>As <a href="psionicPowersAtoC.html#bend-reality" style={{
        "color": "rgb(87, 158, 182)"
      }}>bend reality</a>, but with more
farreaching effects. A reality
revision
can produce any one of the following effects. </p>
    <ul>
      <li>
        <p>Duplicate any <a href="PsionicPsionList.html" style={{
            "color": "rgb(87, 158, 182)"
          }}>psion power</a> of 8th level or
lower, provided
the power
is not prohibited to you.</p>
      </li>
      <li>
        <p>Duplicate any other power (but not a spell) of 6th level
or lower,
such as a <a href="PsionicWarriorList.html" style={{
            "color": "rgb(87, 158, 182)"
          }}>psychic warrior power</a>.</p>
      </li>
      <li>
        <p>Duplicate any psion power of 7th level or lower even if
it&rsquo;s a power
prohibited to you.</p>
      </li>
      <li>
        <p>Undo the harmful effects of many other powers, such as <a href="psionicPowersGtoP.html#microcosm" style={{
            "color": "rgb(87, 158, 182)"
          }}>microcosm</a>, <a href="spellsFtoG.html#geas-quest" style={{
            "color": "rgb(87, 158, 182)"
          }}>geas/quest</a>,
or <a href="psionicPowersGtoP.html#insanity" style={{
            "color": "rgb(87, 158, 182)"
          }}>insanity</a>.</p>
      </li>
      <li>
        <p>Create a nonpsionic item of up to 25,000 gp in value.</p>
      </li>
      <li>
        <p>Create a psionic item, or add to the powers of an existing
psionic
item (see XP cost below).</p>
      </li>
      <li>
        <p>Grant a creature a +1 inherent bonus to an ability score.
Two to five
reality revisions manifested in immediate succession can grant a
creature a +2 to +5 inherent bonus to an ability score. Inherent
bonuses are instantaneous, so they cannot be negated or dispelled. An
inherent bonus cannot exceed +5 for a single ability score. Inherent
bonuses to a particular ability score do not stack; only the best one
applies.</p>
      </li>
      <li>
        <p>Remove injuries and afflictions. A single reality revision
can aid
one creature per manifester level, and all subjects are cured of the
same kind of affliction.
          <p>{`Reality revision can not restore the experience point loss from
manifesting a power or casting a spell, or the level or`}{` `}{`
Constitution loss from being returned to life by those effects that
reduce level or Constitution.`}</p></p>
      </li>
      <li>
        <p>Revive the dead. Reality revision can bring a dead
creature back to
life by duplicating a <a href="spellsPtoR.html#resurrection" style={{
            "color": "rgb(87, 158, 182)"
          }}>resurrection</a> spell. This power
can revive a dead
creature whose body has been destroyed, but the task takes two
manifestations of reality revision, one to recreate the body and
another to infuse the body with life again. Reality revision cannot
prevent a character who is brought back to life from losing a level.</p>
      </li>
      <li>
        <p>Transport travelers. This power can lift one creature per
manifester
level from anywhere on any plane and place those creatures anywhere
else on any plane regardless of local conditions. An unwilling target
gets a Will save to negate the effect, and power resistance (if any)
applies.</p>
      </li>
      <li>
        <p>Undo misfortune. Reality revision can undo a single recent
event.
Manifesting the power forces a reroll of any roll made within the last
round (including your last turn). Reality reshapes itself to
accommodate the new result. The reroll, however, may be as bad as or
worse than the original roll. An unwilling target gets a Will save to
negate the effect, and power resistance (if any) applies.</p>
      </li>
    </ul>
    <p>You can try to use reality revision to produce more powerful
effects
than these, but doing so is dangerous. The manifestation may pervert
your intent into a literal but undesirable fulfillment or only a
partial fulfillment.</p>
    <p>Duplicated powers allow saves and power resistance as normal
(but save
DCs are calculated as though the power is 9th level).</p>
    <p><i>XP Cost:</i> The minimum XP cost for manifesting reality
revision is 5,000 XP. When a manifestation duplicates a power that has
an XP cost, you
must pay 5,000 XP or that cost, whichever is more. When a manifestation
creates or improves a psionic item, you must pay twice the normal XP
cost for crafting or improving the item, plus an additional 5,000 XP.</p>
    <h6><a id="recall-agony"></a>Recall Agony</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience [Mind-Affecting]</span>
    <b>Level:</b> Psion/wilder 2
    <b>Display:</b> Material
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Medium (100 ft. + 10 ft./ level)
    <b>Target:</b> One creature
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> Will half
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 3
    <p>The fabric of time parts to your will, revealing wounds your
foe has
received in the past (or has yet to receive). That foe takes 2d6 points
of damage as the past (or future) impinges briefly on the present.</p>
    <p><b>Augment:</b> For every additional power point you spend,
this power&rsquo;s
damage increases by 1d6 points. For each extra 2d6 points of damage,
this power&rsquo;s save DC increases by 1.</p>
    <h6><a id="recall-death"></a>Recall Death</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience [Death,
Mind-Affecting]</span>
    <b>Level:</b> Psion/wilder 8
    <b>Saving Throw:</b> Will partial; see text
    <b>Power Points:</b> 15
    <p>As <a href="#recall-agony" style={{
        "color": "rgb(87, 158, 182)"
      }}>recall
agony</a>, except the wounds revealed by folding the
fourth
dimension are potentially fatal. If the target fails its Will save. it
dies. If the save succeeds, the target instead takes 5d6 points of
damage.</p>
    <h6><a id="reddopsi"></a>Reddopsi</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychokinesis</span>
    <b>Level:</b> Kineticist 7
    <b>Display:</b> Auditory, mental, and olfactory
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 10 min./level (D)
    <b>Power Points:</b> 13
    <p>When you manifest reddopsi, powers targeted against you
rebound to
affect the original manifester. This effect reverses powers that have
only you as a target (except <a href="psionicPowersDtoF.html#dispel-psionics" style={{
        "color": "rgb(87, 158, 182)"
      }}>dispel psionics</a> and similar
powers or
effects). Powers that affect an area and those that produce effects
can&rsquo;t be reversed. Reddopsi also can&rsquo;t reverse any power
with a range
of touch.</p>
    <p>Should you rebound a power back against a manifester who also
is
protected by reddopsi, the power rebounds once more upon you.</p>
    <h6><a id="remote-view-trap"></a>Remote View Trap</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience [Electricity]</span>
    <b>Level:</b> Psion/wilder 6
    <b>Display:</b> Mental and visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 24 hours + 1 hour/level
    <b>Saving Throw:</b> Will half; see text
    <b>Power Resistance:</b> No
    <b>Power Points:</b> 11
    <p>When others use <a href="psionicPowersAtoC.html#clairvoyant-sense" style={{
        "color": "rgb(87, 158, 182)"
      }}>clairvoyant sense</a>, <a href="psionicPowersQtoW.html#remote-viewing" style={{
        "color": "rgb(87, 158, 182)"
      }}>remote viewing</a>, or other
means of
scrying you from afar, your prepared trap gives them a nasty surprise.
If the scryer fails its saving throw, you are undetected. Moreover, the
would-be observer takes 8d6 points of electricity damage. If the scryer
makes its saving throw, it takes only 4d6 points of electricity damage
and is able to observe you normally. Either way, you are aware of the
attempt to view you, but not of the viewer or the viewer&rsquo;s
location. It
is possible that you might recognize the quasireal viewpoint of someone
using the remote viewing power if you could pierce its <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisibility</a>
(which is true for remote viewing whether or not you use this power).</p>
    <h6><a id="remote-viewing"></a>Remote Viewing</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience (Scrying; see
text)</span>
    <b>Level:</b> Seer 4
    <b>Display:</b> Mental
    <b>Manifesting Time:</b> 1 hour
    <b>Range:</b> See text
    <b>Effect:</b> Quasi-real viewpoint
    <b>Duration:</b> 1 min./level (D)
    <b>Saving Throw:</b> Will negates
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 7, XP
    <p>You send your mind across space and dimensions, forming it
into a
quasireal viewpoint from which you can see and hear some creature
located at any distance from you, even if planar boundaries separate
you. If the subject succeeds on a Will save, the remote viewing attempt
fails, and you can&rsquo;t attempt to view that creature again for at
least
24 hours. The difficulty of the save depends on how well you know the
subject and what sort of physical connection (if any) you have to that
creature. Furthermore, if the subject is on another plane, it gets a +5
bonus on its Will save.</p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "60%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "70%"
          }}>Knowledge</th>
          <th>Will Save Modifier</th>
        </tr>
        <tr className="odd-row">
          <td>None<sup>1</sup></td>
          <td>+10</td>
        </tr>
        <tr>
          <td>Secondhand (you have heard of the subject)</td>
          <td>+5</td>
        </tr>
        <tr className="odd-row">
          <td>Firsthand (you have met the subject)</td>
          <td>+0</td>
        </tr>
        <tr>
          <td className="last-row">Familiar (you know the subject well)</td>
          <td className="last-row">&ndash;5</td>
        </tr>
        <tr>
          <td colSpan="2">1 <i>You must have some sort of connection
to a creature you have no knowledge of.</i></td>
        </tr>
      </tbody>
    </table>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "60%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "70%"
          }}>Connection</th>
          <th>Will Save Modifier</th>
        </tr>
        <tr className="odd-row">
          <td>Likeness or picture</td>
          <td>&ndash;2</td>
        </tr>
        <tr>
          <td>Possession or garment</td>
          <td>&ndash;4</td>
        </tr>
        <tr className="odd-row">
          <td>Body part, lock of hair, bit of nail, etc.</td>
          <td>&ndash;10</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>Subject on another plane</td>
          <td style={{
            "verticalAlign": "top"
          }}>+5</td>
        </tr>
      </tbody>
    </table>
    <p>If the subject fails its Will save, your mind coalesces a
quasi-real
viewpoint near enough to the subject to see and hear the subject and
its immediate surroundings (up 30 feet in all directions away from the
subject).</p>
    <p>While the remote viewing lasts, your real body remains
unmoving and
unaware of your actual surroundings. On the other hand, your quasi-real
viewpoint is treated in some ways as if it were an <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a>
ectoplasmic form of yourself, except as follows. This power is of the
Scrying subdiscipline, but use the following information in place of
the standard scrying sensor. As a quasi-real viewpoint, you can speak
(though your voice is whispery).</p>
    <p>You may potentially be sensed by the subject of your viewing
(subjects
who can see or sense invisible or hidden creatures automatically sense
you; otherwise you make a <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a> check with a +40 bonus to
escape
detection if immobile, or a +20 bonus if moving). You could be attacked
(although if you become subject to <a href="psionicPowersDtoF.html#dispel-psionics" style={{
        "color": "rgb(87, 158, 182)"
      }}>dispel psionics</a>, the remote
viewing
simply ends). If the subject moves, you can attempt to follow it at a
speed of 20 feet, though if it gets farther than 30 feet from you (or
you move farther than 30 feet from it), the power ends.</p>
    <p>You can attempt to manifest one power through your quasi-real
viewpoint, but you must make a <a href="psionicSkills.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> check (DC 20 +
level of
the power you wish to manifest) to succeed. Manifesting (or attempting
and failing to manifest) a power immediately ends the remote viewing.</p>
    <p>Furthermore, all powers from your quasi-real viewpoint cost
twice the
usual number of power points (you can&rsquo;t exceed the power point
limit
set by your manifester level, so you are restricted to manifesting
lower-level powers than you otherwise could). Power points you spend as
a quasireal viewpoint are drained from your real body.</p>
    <p><i>XP Cost:</i> 20 XP.</p>
    <h6><a id="restoration-psionic"></a>Restoration, Psionic</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism (Healing)</span>
    <b>Level:</b> Egoist 6
    <b>Display:</b> Material
    <b>Manifesting Time:</b> 3 rounds
    <b>Range:</b> Touch
    <b>Target:</b> Creature touched
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> Will negates (harmless)
    <b>Power Resistance:</b> Yes (harmless)
    <b>Power Points:</b> 11
    <p>This power cures all <a href="abilitiesAndConditions.html#ability-damaged" style={{
        "color": "rgb(87, 158, 182)"
      }}>ability damage</a>, and it restores
all
points <a href="abilitiesAndConditions.html#ability-drained" style={{
        "color": "rgb(87, 158, 182)"
      }}>drained</a>
from a single ability score (your choice if more than one score is
drained). It also eliminates any <a href="abilitiesAndConditions.html#fatigued" style={{
        "color": "rgb(87, 158, 182)"
      }}>fatigue</a> or <a href="abilitiesAndConditions.html#exhausted" style={{
        "color": "rgb(87, 158, 182)"
      }}>exhaustion</a> suffered by the
target. Restoration does not restore levels or Constitution points lost
due to death.</p>
    <p>Restoration can remove <a href="abilitiesAndConditions.html#energy-drained" style={{
        "color": "rgb(87, 158, 182)"
      }}>negative levels</a>. It can also
restore
one level
to a creature who has had a level drained, if the number of days since
the creature lost the level is equal to or less than your manifester
level. In such a case, restoration brings the creature up to the
minimum number of experience points necessary to advance it to the next
higher level, gaining it an additional Hit Die and level benefits
accordingly.</p>
    <h6><a id="restore-extremity"></a>Restore Extremity</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism (Healing)</span>
    <b>Level:</b> Egoist 5
    <b>Display:</b> Auditory
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Touch
    <b>Target:</b> Creature touched
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> Will half (harmless)
    <b>Power Resistance:</b> Yes (harmless)
    <b>Power Points:</b> 9
    <p>You restore a severed extremity to a creature that has lost a
digit,
hand, arm, leg, or even its head. This power does not restore life, but
it returns a lost extremity to a living or dead creature if the
creature is otherwise mostly intact. The original extremity need not be
present when this power is manifested; a new extremity is created by
the power. If a head is restored to a body, the original head (if not
already destroyed) loses all spark of identity, and can be considered
so much dead tissue.</p>
    <h6><a id="retrieve"></a>Retrieve</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychoportation (Teleportation)</span>
    <b>Level:</b> Psion/wilder 6
    <b>Display:</b> Visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Medium (100 ft. + 10 ft./ level)
    <b>Target:</b> One object you can hold or carry in one hand,
weighing up to 10
lb./level
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> Will negates; see text
    <b>Power Resistance:</b> No
    <b>Power Points:</b> 11
    <p>You automatically teleport an item you can see within range
directly to
your hand. If the object is in the possession of an opponent, it comes
to your hand if your opponent fails a Will save.</p>
    <p><b>Augment:</b> For every additional power point you spend,
the weight limit
of the target increases by 10 pounds.</p>
    <h6><a id="schism"></a>Schism</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <b>Level:</b> Telepath 4
    <b>Display:</b> Auditory and visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 round/level (D)
    <b>Power Points:</b> 7
    <p>Your mind splits into two independent parts. Each part
functions in
complete autonomy, like two characters in one body. Your new
&ldquo;second
mind&rdquo; does not control your body physically but is free to take
one
standard action in each round if the action is purely mental (such as
manifesting a power) in the same round you take your normal actions.</p>
    <p>Your second mind can manifest powers using your power point
reserve,
but only as if your manifester level were six lower than it is. Your
second mind doesn&rsquo;t provoke attacks of opportunity when
manifesting a
power, because doing so doesn&rsquo;t distract your primary mind.</p>
    <p>Your second mind takes its first action on your turn in the
round after
schism is manifested.</p>
    <p>Both your minds communicate with each other telepathically. If
you are
subject to a compulsion or charm effect while you are of two minds,
make a second saving throw if you fail the first. If you fail both,
then the schism ends and you are affected normally by the power. If you
fail just one, the schism ends immediately, but you are not subject to
the compulsion or charm.</p>
    <p>Your second mind does not gain any advantages if you are
subject to a <a href="spellsHtoL.html#haste" style={{
        "color": "rgb(87, 158, 182)"
      }}>haste</a>
effect, although you gain the overall standard benefits.</p>
    <h6><a id="second-chance"></a>Second Chance</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience</span>
    <b>Level:</b> Seer 5
    <b>Display:</b> Mental
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 round/level or until discharged
    <b>Power Points:</b> 9
    <p>You take a hand in influencing the probable outcomes of your
immediate
environment. You see the many alternative branches that reality could
take in the next few seconds, and with this foreknowledge you gain the
ability to reroll one attack roll, one saving throw, one ability check,
or one skill check each round. You must take the result of the reroll,
even if it&rsquo;s worse than the original roll. You do not have to
make
another roll if satisfied with your original roll.</p>
    <h6><a id="sense-link"></a>Sense Link</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <b>Level:</b> Psion/wilder 1
    <b>Display:</b> Visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Medium (100 ft. + 10 ft./level)
    <b>Target:</b> One willing creature
    <b>Duration:</b> Concentration, up to 1min./level
    <b>Power Points:</b> 1
    <p>You perceive what the subject creature perceives using its
sight,
hearing, taste, or smell. Only one sense is linked, and you cannot
switch between senses with the same manifestation.</p>
    <p>You make any skill checks involving senses, such as <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
or <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>,
as
the subject, and only within the subject&rsquo;s field of view. You
lose your
Dexterity bonus to AC while directly sensing what the subject senses.</p>
    <p>Once sense link is manifested, the link persists even if the
subject
moves out of the range of the original manifestation (but the link does
not work across planes). You do not control the subject, nor can you
communicate with it by means of this power.</p>
    <p>The strength of the subject&rsquo;s linked sense could be
enhanced
by other
powers or items, allowing you the same enhanced sense. You are subject
to any gaze attack affecting the subject creature (if you linked
vision). If you are <a href="abilitiesAndConditions.html#blinded" style={{
        "color": "rgb(87, 158, 182)"
      }}>blinded</a> or <a href="abilitiesAndConditions.html#deafened" style={{
        "color": "rgb(87, 158, 182)"
      }}>deafened</a>, or suffer some other
sensory
deprivation, the linked creature functions as an independent sensory
organ, and provides you the benefit of the linked sense from its
perspective while this power&rsquo;s duration lasts.</p>
    <p><b>Augment:</b> You can augment this power in one or both of
the following
ways.</p>
    <p>1. If you spend 2 additional power points, you can have the
subject
perceive one of your senses instead of the other way around.</p>
    <p>2. If you spend 4 additional power points, you can link to a
second
sense of the same subject.</p>
    <h6><a id="sense-link-forced"></a>Sense Link, Forced</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <b>Level:</b> Psion/wilder 2
    <b>Saving Throw:</b> Will negates
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 3
    <p>As <a href="#sense-link" style={{
        "color": "rgb(87, 158, 182)"
      }}>sense
link</a>, except you can use this power on any creature
(willing
or unwilling), and this power can&rsquo;t be augmented.</p>
    <h6><a id="sensitivity-to-psychic-impressions"></a>Sensitivity
to Psychic Impressions</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience</span>
    <b>Level:</b> Seer 2
    <b>Display:</b> Auditory and material
    <b>Manifesting Time:</b> 1 hour
    <b>Range:</b> Close (25 ft. + 5 ft./2 levels)
    <b>Area:</b> Spread with a radius of 25 ft. + 5 ft./2 levels,
centered on you
    <b>Duration:</b> Concentration, up to 10 min./level
    <b>Saving Throw:</b> None
    <b>Power Resistance:</b> No
    <b>Power Points:</b> 3
    <p>You gain historical vision in a given location. Rooms,
streets,
tunnels, and other discrete locations accumulate psychic impressions
left by powerful emotions experienced in a given area. These
impressions offer you a picture of the location&rsquo;s past.</p>
    <p>The types of events most likely to leave psychic impressions
are those
that elicited strong emotions: battles and betrayals, marriages and
murders, births and great pain, or any other event where one emotion
dominates. Everyday occurrences leave no residue for a manifester to
detect.</p>
    <p>The vision of the event is dreamlike and shadowy. You do not
gain
special knowledge of those involved in the vision, though you might be
able to read large banners or other writing if they are in your
language.</p>
    <p>Beginning with the most recent significant event at a location
and
working backward in time, you can sense one distinct event for every 10
minutes you maintain concentration, if any such events exist to be
sensed. Your sensitivity extends into the past a maximum number of
years equal to 100 x your manifester level.</p>
    <h6><a id="sequester-psionic"></a>Sequester, Psionic</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience</span>
    <b>Level:</b> Psion/wilder 7
    <b>Display:</b> None
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Touch
    <b>Target:</b> One willing creature or one object (up to a 2-ft.
cube/level)
touched
    <b>Duration:</b> One day/level (D)
    <b>Saving Throw:</b> None or Will negates (object)
    <b>Power Resistance:</b> No or Yes (object)
    <b>Power Points:</b> 13, XP
    <p>As the <a href="spellsS.html#sequester" style={{
        "color": "rgb(87, 158, 182)"
      }}>sequester</a> spell, except as noted
here. </p>
    <p><i>XP Cost:</i> 75 XP.</p>
    <h6><a id="shadow-body"></a>Shadow Body</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <b>Level:</b> Psion/wilder 8
    <b>Display:</b> Auditory
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 min./level (D)
    <b>Power Points:</b> 15
    <p>Your body and all your equipment are subsumed by your shadow.
As a
living shadow, you blend perfectly into any other shadow and vanish in
darkness. You appear as an unattached shadow in areas of full light.</p>
    <p>You can move at your normal speed, on any surface, including
walls and
ceilings, as well as across the surfaces of liquids - even up the face
of
a waterfall.</p>
    <p>Your space does not change, so you cannot move into locations
you would
not normally be able to move into.</p>
    <p>While in your shadow body, you gain <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#damage-reduction">damage
reduction</a> 10/magic
and <a href="monsterTypes.html#darkvision" style={{
        "color": "rgb(87, 158, 182)"
      }}>darkvision</a> out to 60 feet. You
are immune to extra damage from critical
hits, <a href="abilitiesAndConditions.html#ability-damaged" style={{
        "color": "rgb(87, 158, 182)"
      }}>ability damage</a>, <a href="abilitiesAndConditions.html#disease" style={{
        "color": "rgb(87, 158, 182)"
      }}>disease</a>, drowning, and <a href="abilitiesAndConditions.html#poison" style={{
        "color": "rgb(87, 158, 182)"
      }}>poison</a>. You take only half
damage from acid, electricity, and fire of all kinds.</p>
    <p>While affected by this power, you can be detected by powers
that read
thoughts, life, or presences (including <a href="#true-seeing-psionic" style={{
        "color": "rgb(87, 158, 182)"
      }}>true seeing</a>), or if you make
suspicious movements in lighted areas.</p>
    <p>You cannot harm anyone physically or manipulate any objects,
but you
can use your powers normally. Doing so may attract notice, but if you
remain in a shadowed area, you get a +15 bonus on your <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
check to
remain unnoticed.</p>
    <h6><a id="share-pain"></a>Share Pain</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <b>Level:</b> Psion/wilder 2
    <b>Display:</b> Material and mental
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Touch
    <b>Targets:</b> You and one willing creature, or two willing
creatures; see
text
    <b>Duration:</b> 1 hour/level (D)
    <b>Power Points:</b> 3
    <p>This power creates a psychometabolic connection between you
and a
willing subject so that some of your wounds are transferred to the
subject. You take half damage from all attacks that deal hit point
damage to you, and the subject takes the remainder. The amount of
damage not taken by you is taken by the subject. If your hit points are
reduced by a lowered Constitution score, that reduction is not shared
with the subject because it is not a form of hit point damage. When
this power ends, subsequent damage is no longer divided between the
subject and you, but damage already shared is not reassigned.</p>
    <p>If you and the subject move farther away from each other than
close
range, the power ends.</p>
    <p>You can manifest this power on two willing subjects, one of
which you
designate to share its damage with the other.</p>
    <h6><a id="share-pain-forced"></a>Share Pain, Forced</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <b>Level:</b> Psion/wilder 3
    <b>Range:</b> Close (25 ft. + 5 ft./2 levels)
    <b>Target:</b> One creature
    <b>Duration:</b> 1 round/level (D)
    <b>Saving Throw:</b> Fortitude negates
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 5
    <p>As <a href="#share-pain" style={{
        "color": "rgb(87, 158, 182)"
      }}>share
pain</a>, except as noted here.</p>
    <p>You attempt to force the sharing of your wounds with an
unwilling
creature, and for less time. If you are immune to the type of damage
dealt, or if you convert lethal damage into nonlethal damage, the
target takes no damage.</p>
    <p><b>Augment:</b> For every 2 additional power points you spend,
this power&rsquo;s
save DC increases by 1.</p>
    <h6><a id="shatter-mind-blank"></a>Shatter Mind Blank</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy</span>
    <b>Level:</b> Psion/wilder 5
    <b>Display:</b> Olfactory
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> 30 ft.
    <b>Area:</b> 30-ft.-radius burst centered on you
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> Will negates
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 9
    <p>This power can negate a <a href="psionicPowersGtoP.html#mind-blank-psionic" style={{
        "color": "rgb(87, 158, 182)"
      }}>psionic mind blank</a> or a <a href="psionicPowersGtoP.html#mind-blank-personal" style={{
        "color": "rgb(87, 158, 182)"
      }}>personal mind
blank</a>
affecting the target. If the target fails its save and does not
overcome your attempt with its power resistance, you can shatter the
mind blank by making a successful check (1d20 + your manifester level,
maximum +20) against a DC equal to 11 + the manifester level of the
creator of the mind blank effect. If you succeed, the psionic mind
blank or personal mind blank ends, allowing you to affect the target
thereafter with mind-affecting powers.</p>
    <h6><a id="skate"></a>Skate</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychoportation</span>
    <b>Level:</b> Psion/wilder 1, psychic warrior 1
    <b>Display:</b> Material and visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal or touch; see text
    <b>Target:</b> You or one willing creature or one unattended
object (total
weight up to 100 lb./level); see text
    <b>Duration:</b> 1 min./level (D)
    <b>Saving Throw:</b> None
    <b>Power Resistance:</b> Yes (harmless, object)
    <b>Power Points:</b> 1
    <p>You, another willing creature, or an unattended object can
slide along
solid ground as if on smooth ice. If you manifest skate on yourself or
another creature, the subject of the power retains equilibrium by
mental desire alone, allowing her to gracefully skate along the ground,
turn, or stop suddenly as desired. The skater&rsquo;s land speed
increases by
15 feet. (This adjustment is treated as an enhancement bonus.) As with
any effect that increases speed, this power affects the subject&rsquo;s
maximum jumping distance.</p>
    <p>The subject can skate up or down any incline or decline she
could
normally walk upon without mishap, though skating up an incline reduces
the subject&rsquo;s speed to normal, while skating down a decline
increases
her speed by an additional 15 feet. (This adjustment is treated as a
circumstance bonus.)</p>
    <p>If you manifest skate on an object, treat the object as having
only
one-tenth of its normal weight for the purpose of dragging it along the
ground.</p>
    <h6><a id="solicit-psicrystal"></a>Solicit Psicrystal</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy</span>
    <b>Level:</b> Psion/wilder 3
    <b>Display:</b> Auditory
    <b>Manifesting Time:</b> 1 swift action
    <b>Range:</b> Close (25 ft. + 5 ft./2 levels)
    <b>Target:</b> Your psicrystal
    <b>Duration:</b> 1 round/level (D)
    <b>Saving Throw:</b> No
    <b>Power Resistance:</b> No
    <b>Power Points:</b> 5
    <p>Your psicrystal takes over the responsibility of maintaining
concentration on any single power you have manifested and are
concentrating on. While maintaining
this
concentration, the psicrystal is limited to move actions in each round,
as normal. When the duration of solicit psicrystal expires, the power
you transferred to the psicrystal ends (even if this would mean that
the power ends earlier than normal). If necessary, the psicrystal makes
      <a href="psionicSkills.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> checks using your
Concentration modifier.</p>
    <p>You can manifest this power (and transfer the responsibility)
with an
instant thought, quickly enough to gain the benefit of the power before
you take any other actions in a round. Manifesting the power is a swift
action, like manifesting a quickened power, and it counts toward the
normal limit of one quickened power per round. You cannot manifest this
power when it isn&rsquo;t your turn.</p>
    <p><b>Augment:</b> For every additional power point you spend,
this power&rsquo;s
maximum duration increases by 1 round.</p>
    <h6><a id="steadfast-perception"></a>Steadfast Perception</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience</span>
    <b>Level:</b> Psychic warrior 4
    <b>Display:</b> Visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 10 min./level (D)
    <b>Power Points:</b> 7
    <p>Your vision cannot be distracted or misled, granting you
immunity to
all figments and glamers (such as <a href="spellsHtoL.html#invisibility" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisibility</a>).
Moreover, your <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
and <a href="skillsAll.html#search" style={{
        "color": "rgb(87, 158, 182)"
      }}>Search</a>
checks receive a +6 enhancement bonus for the duration of
this power. This power also grants you another saving throw against
someone using <a href="psionicPowersDtoF.html#false-sensory-input" style={{
        "color": "rgb(87, 158, 182)"
      }}>false sensory input</a> on you, but
you must realize that
that power has been used in order to know enough to manifest steadfast
perception.</p>
    <h6><a id="stomp"></a>Stomp</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychokinesis</span>
    <b>Level:</b> Psychic warrior 1
    <b>Display:</b> Auditory and visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> 20 ft.
    <b>Area:</b> Cone-shaped spread
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> Reflex negates
    <b>Power Resistance:</b> No
    <b>Power Points:</b> 1
    <p>Your foot stomp precipitates a psychokinetic shock wave that
travels
along the ground, toppling creatures and loose objects. The shock wave
affects only creatures standing on the ground within the power&rsquo;s
area.
Creatures that fail their saves are thrown to the ground, become <a href="abilitiesAndConditions.html#prone" style={{
        "color": "rgb(87, 158, 182)"
      }}>prone</a>,
and take 1d4 points of nonlethal damage.</p>
    <p><b>Augment:</b> For every additional power point you spend,
this power&rsquo;s
nonlethal damage increases by 1d4 points.</p>
    <h6><a id="strength-of-my-enemy"></a>Strength of My Enemy</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <b>Level:</b> Psychic warrior 2
    <b>Display:</b> Visual; see text
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 round/level (D)
    <b>Power Points:</b> 3
    <p>You gain the ability to siphon away your enemy&rsquo;s
strength for
your own
use. One of your natural or manufactured weapons becomes the instrument
of your desire, and deals 1 point of Strength damage on each successful
hit. You gain that point of Strength as an enhancement bonus to your
Strength score. Strength you siphon from different foes is tracked
separately - the total siphoned from each individual foe is considered
a
separate enhancement bonus to your Strength (maximum +8), and you gain
only the highest total.</p>
    <p><b>Augment:</b> You can augment this power in one or both of
the following
ways.</p>
    <p>1. For every 3 additional power points you spend, the maximum
enhancement bonus you can add to your Strength increases by 2.</p>
    <p>2. If you spend 6 additional power points, you can manifest
this power
as a swift action.</p>
    <h6><a id="suggestion-psionic"></a>Suggestion, Psionic</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy (Compulsion)
[Mind-Affecting, Language-Dependent]</span>
    <b>Level:</b> Telepath 2
    <b>Display:</b> Auditory
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Close (25 ft. + 5 ft./2 levels)
    <b>Target:</b> One creature
    <b>Duration:</b> 1 hour/level or until completed
    <b>Saving Throw:</b> Will negates
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 3
    <p>As the <a href="spellsS.html#suggestion" style={{
        "color": "rgb(87, 158, 182)"
      }}>suggestion</a> spell, except as
noted here.</p>
    <p><b>Augment:</b> For every 2 additional power points you spend,
this power can
affect an additional target. Any additional target cannot be more than
15 feet from another target of the power.</p>
    <h6><a id="suspend-life"></a>Suspend Life</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <b>Level:</b> Psion/wilder 6, psychic warrior 6
    <b>Display:</b> Olfactory
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> Permanent unless ended or dismissed; see text
    <b>Power Points:</b> 11
    <p>You can place yourself into a trance so deep that you are
almost in
suspended animation. Even powers that detect life or thought are
incapable of determining that you are alive.</p>
    <p>While you are suspended, you are aware of your surroundings.
You feel
the passage of one day for every year that actually passes. Though on a
slower schedule, you grow hungry after a &ldquo;day&rdquo; without food
(though a
year passes in actuality) and begin to suffer the effects of thirst and
starvation as appropriate.</p>
    <p>If you take any damage, you come out of your trance 4 rounds
later. The
trance can also be ended by a successful use of <a href="psionicPowersDtoF.html#dispel-psionics" style={{
        "color": "rgb(87, 158, 182)"
      }}>dispel psionics</a>. If you
choose to dismiss the power, your trance ends 10 rounds later.</p>
    <h6><a id="sustenance"></a>Sustenance</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <b>Level:</b> Psion/wilder 2, psychic warrior 2
    <b>Display:</b> Material
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> Instantaneous
    <b>Power Points:</b> 3
    <p>You can go without food and water for one day. Each time you
manifest
this power, your body manufactures sufficient solid and liquid
nourishment to satisfy your needs for that time.</p>
    <h6><a id="swarm-of-crystals"></a>Swarm of Crystals</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Metacreativity (Creation)</span>
    <b>Level:</b> Psion/wilder 2
    <b>Display:</b> Material
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> 15 ft.
    <b>Area:</b> Cone-shaped spread
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> None
    <b>Power Resistance:</b> No
    <b>Power Points:</b> 3
    <p>Thousands of tiny crystal shards spray forth in an arc from
your hand.
These razorlike crystals slice everything in their path. Anyone caught
in the cone takes 3d4 points of slashing damage.</p>
    <p><b>Augment:</b> For every additional power point you spend,
this power&rsquo;s
damage increases by 1d4 points.</p>
    <h6><a id="synesthete"></a>Synesthete</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <b>Level:</b> Psion/wilder 1, psychic warrior 1
    <b>Display:</b> Material
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 10 min./level (D)
    <b>Power Points:</b> 1
    <p>You receive one kind of sensory input when a different sense
is
stimulated. In particular, you can either feel light or feel sound. You
can shift your stimulated sense between these two options once per
round as a swift action. Your senses continue to work normally as well,
unless they are impaired for some reason.</p>
    <p>Your face must be uncovered to use this power, because it is
the skin
of your face that acts as the sensory receiver.</p>
    <p>If you are feeling light by absorbing ambient light onto your
skin, you
have your normal visual abilities (except for darkvision), even if your
eyes are closed or you are <a href="abilitiesAndConditions.html#blinded" style={{
        "color": "rgb(87, 158, 182)"
      }}>blinded</a>. If your eyes are
working normally,
you gain a +4 circumstance bonus on all <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a> and <a href="skillsAll.html#search" style={{
        "color": "rgb(87, 158, 182)"
      }}>Search</a>
checks. While
feeling light, you are immune to gaze attacks.</p>
    <p>If you are feeling sound by absorbing sound onto your skin and
your
ears are working normally, the expanded audio input provides you with a
+4 circumstance bonus on <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a> checks.</p>
    <p>Psionic or magical displacement effects, invisibility effects,
illusions, and other similar effects confuse your synesthete senses
just as they would your normal senses.</p>
    <p>You can also use this power to see sound if you are <a href="abilitiesAndConditions.html#deafened" style={{
        "color": "rgb(87, 158, 182)"
      }}>deafened</a>,
or hear
light if you are blinded, thus removing all penalties associated with
either condition (though you gain no bonuses for using the power in
this way if you are not deafened or blinded).</p>
    <h6><a id="telekinetic-force"></a>Telekinetic Force</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychokinesis [Force]</span>
    <b>Level:</b> Psion/wilder 3
    <b>Display:</b> Visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Medium (100 ft. + 10 ft./ level)
    <b>Target:</b> One object at a time
    <b>Duration:</b> Concentration, up to 1 round/level
    <b>Saving Throw:</b> Will negates (object); see text
    <b>Power Resistance:</b> Yes (object)
    <b>Power Points:</b> 5
    <p>You move an object by concentrating your mind upon its current
location
and then the location you desire, creating a sustained force. You can
move an object weighing no more than 250 pounds up to 20 feet per
round. A creature can negate the effect on an object it possesses with
a successful Will save or with power resistance. The weight can be
moved across the ground or through the air. This power ends if the
object is forced out of range. If you cease concentration, the object
falls or stops.</p>
    <p>You can drop a weight and pick up another during the
power&rsquo;s
duration,
as long as you don&rsquo;t stop concentrating on maintaining the power.
An
object can be ally manipulated as if you were moving it with
one hand.</p>
    <p>If you spend at least 5 rounds concentrating on an unattended
object,
you can attempt to break or burst it as if making a Strength check,
except that you apply your key ability modifier to the check instead of
your Strength modifier.</p>
    <p><b>Augment:</b> For every additional power point you spend,
the weight limit
of the target increases by 25 pounds.</p>
    <h6><a id="telekinetic-maneuver"></a>Telekinetic Maneuver</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychokinesis [Force]</span>
    <b>Level:</b> Psion/wilder 4
    <b>Display:</b> Visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Medium (100 ft. + 10 ft./ level)
    <b>Target:</b> One creature
    <b>Duration:</b> Concentration, up to 1 round/level
    <b>Saving Throw:</b> None
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 7
    <p>You can affect a foe by concentrating your mind upon its
current status
and the status you desire, once per round. You can perform a <a href="specialAttacks.html#bull-rush" style={{
        "color": "rgb(87, 158, 182)"
      }}>bull
rush</a>,
a <a href="specialAttacks.html#disarm" style={{
        "color": "rgb(87, 158, 182)"
      }}>disarm</a>, a <a href="specialAttacks.html#grapple" style={{
        "color": "rgb(87, 158, 182)"
      }}>grapple</a>
(including a pin), or a <a href="specialAttacks.html#trip" style={{
        "color": "rgb(87, 158, 182)"
      }}>trip</a>. Resolve these
attempts as normal, except that they don&rsquo;t provoke attacks of
opportunity, you use your manifester level in place of your base attack
bonus (for disarm and grapple attempts), you use your Intelligence
modifier in place of your Strength modifier or Dexterity modifier, and
a failed attempt doesn&rsquo;t allow a reactive attempt by the target
(such
as normally allowed on disarm or trip attempts). No save is allowed
against these attempts, but power resistance applies normally.</p>
    <p><b>Augment:</b> For every 2 additional power points you spend,
this power
grants a +1 bonus on your checks involving bull rush, disarm, grapple,
or trip attempts.</p>
    <h6><a id="telekinetic-sphere-psionic"></a>Telekinetic Sphere, Psionic</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychokinesis [Force]</span>
    <b>Level:</b> Kineticist 8
    <b>Display:</b> Material
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Close (25 ft. + 5 ft./2 levels)
    <b>Effect:</b> 1-ft.-diameter/level sphere, centered around
creatures or
objects
    <b>Duration:</b> 1 min./level (D)
    <b>Saving Throw:</b> Reflex negates (object)
    <b>Power Resistance:</b> Yes (object)
    <b>Power Points:</b> 15
    <p>As the <a href="spellsTtoZ.html#telekinetic-sphere" style={{
        "color": "rgb(87, 158, 182)"
      }}>telekinetic sphere</a> spell, except
as noted here.</p>
    <h6><a id="telekinetic-thrust"></a>Telekinetic Thrust</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychokinesis</span>
    <b>Level:</b> Psion/wilder 3
    <b>Display:</b> Visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Medium (100 ft. + 10 ft./ level)
    <span style={{
      "fontWeight": "bold"
    }}>Target or </span><b>Targets:</b>
One or more objects or creatures with a total weight
of 250 lb. or less
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> Will negates or Will negates (object); see
text
    <b>Power Resistance:</b> Yes or Yes (object); see text
    <b>Power Points:</b> 5
    <p>You can affect one or more objects or creatures by
concentrating your
mind upon, sending them in a deadly hail at your foes - or simply by
hurling your foe! You can hurl one object or creature per manifester
level (maximum fifteen separate targets), as long as all are within the
power&rsquo;s range and each is no more than 10 feet away from another
one.
Each object or creature can be hurled a maximum distance of 10 feet per
level.</p>
    <p>You must succeed on ranged attack rolls (one per creature or
object
thrown) to hit the target of the hurled items with the items, applying
your Intelligence modifier to the attack roll instead of your Dexterity
modifier. Hurled weapons deal their standard damage (your Strength
bonus does not apply; arrows or bolts deal damage as daggers of their
size when used in this manner). Other objects deal damage ranging from
1 point per 25 pounds of weight (for less dangerous objects such as an
empty barrel) to 1d6 points per 25 pounds of weight (for hard, dense
objects such as a boulder).</p>
    <p>Creatures are allowed Will saves (and power resistance) to
negate the
effect, as are those whose held possessions are targeted by this power.</p>
    <p>If you use this power to hurl a creature against a solid
surface, it
takes damage as if it had fallen 10 feet (1d6 points).</p>
    <p><b>Augment:</b> For every additional power point you spend,
the weight limit
of the target or targets increases by 25 pounds.</p>
    <h6><a id="telempathic-projection"></a>Telempathic Projection</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy (Charm)
[Mind-Affecting]</span>
    <b>Level:</b> Psion/wilder 1
    <b>Display:</b> Visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Medium (100 ft. + 10 ft./ level)
    <b>Target:</b> One creature
    <b>Duration:</b> 1 min./level
    <b>Saving Throw:</b> Will negates
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 1
    <p>You alter the subject&rsquo;s mood, adjusting its attitude
toward
you by one
step in a positive direction. For instance, an unfriendly creature can
be made indifferent, or a hostile creature unfriendly. You can grant a
+4 bonus on your own (or others&rsquo;) <a href="skillsAll.html#bluff" style={{
        "color": "rgb(87, 158, 182)"
      }}>Bluff</a>, <a href="skillsAll.html#diplomacy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Diplomacy</a>,
      <a href="skillsAll.html#intimidate" style={{
        "color": "rgb(87, 158, 182)"
      }}>Intimidate</a>, <a href="skillsAll.html#perform" style={{
        "color": "rgb(87, 158, 182)"
      }}>Perform</a>,
or <a href="skillsAll.html#sense-motive" style={{
        "color": "rgb(87, 158, 182)"
      }}>Sense Motive</a> checks involving
the affected creature.</p>
    <h6><a id="teleport-psionic"></a>Teleport, Psionic</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychoportation (Teleportation)</span>
    <b>Level:</b> Nomad 5
    <b>Display:</b> Visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal and touch
    <span style={{
      "fontWeight": "bold"
    }}>Target or </span><b>Targets:</b>
You and touched objects or other touched willing
creatures
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> None or Will negates (object)
    <b>Power Resistance:</b> No or Yes (object)
    <b>Power Points:</b> 9
    <p>As the <a href="spellsTtoZ.html#teleport" style={{
        "color": "rgb(87, 158, 182)"
      }}>teleport</a> spell, except as noted
here.</p>
    <h6><a id="teleport-psionic-greater"></a>Teleport, Psionic
Greater</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychoportation (Teleportation)</span>
    <b>Level:</b> Psion/wilder 8
    <b>Power Points:</b> 15
    <p>As the <a href="spellsTtoZ.html#greater-teleport" style={{
        "color": "rgb(87, 158, 182)"
      }}>greater teleport</a> spell, except
as noted here.</p>
    <h6><a id="teleportation-circle-psionic"></a>Teleportation
Circle, Psionic</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychoportation (Teleportation)</span>
    <b>Level:</b> Nomad 9
    <b>Display:</b> Mental
    <b>Manifesting Time:</b> 10 minutes
    <b>Range:</b> 0 ft.
    <b>Effect:</b> 5-ft.-radius circle that teleports those who
activate it
    <b>Duration:</b> 10 min./level (D)
    <b>Saving Throw:</b> None
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 17
    <p>As the <a href="spellsTtoZ.html#teleportation-circle" style={{
        "color": "rgb(87, 158, 182)"
      }}>teleportation circle</a> spell,
except as noted here.</p>
    <h6><a id="teleport-trigger"></a>Teleport Trigger</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychoportation (Teleportation)</span>
    <b>Level:</b> Nomad 5
    <b>Display:</b> Material
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 hour/level (D)
    <b>Power Points:</b> 9
    <p>You specify a situation that triggers your automatic
manifestation of a <a href="#teleport-psionic" style={{
        "color": "rgb(87, 158, 182)"
      }}>psionic
teleport</a>, taking you to a predetermined location. You must know
the psionic teleport power and have sufficient power points to manifest
it when the specified situation occurs.</p>
    <p>The teleport trigger goes off on the initiative count
immediately after
the specified situation occurs, even if you are flat-footed or you have
already taken your turn in the current round. The specified situation
can be described in general terms or specific terms.</p>
    <h6><a id="temporal-acceleration"></a>Temporal Acceleration</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychoportation</span>
    <b>Level:</b> Psion/wilder 6
    <b>Display:</b> None
    <b>Manifesting Time:</b> 1 swift action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 round (in apparent time); see text
    <b>Power Points:</b> 11
    <p>You enter another time frame, speeding up so greatly that all
other
creatures seem frozen, though they are actually still moving at normal
speed. You are free to act for 1 round of apparent time. You can
manifest powers, cast spells, move, or perform other types of actions,
subject to the restrictions outlined below.</p>
    <p>While your temporal acceleration is in effect, other creatures
are
invulnerable to your attacks and powers. This means you cannot target a
creature with any attack or power. However, a power you manifest that
affects an area and has a duration longer than the remaining duration
of your temporal acceleration has its normal effect on creatures in the
area once this power ends.</p>
    <p>You can affect an unattended object but not an object held,
carried, or
worn by another creature. You are undetectable by any means while your
temporal acceleration lasts.</p>
    <p>While under the effect of this power, you cannot enter an area
protected by a <a href="psionicPowersGtoP.html#null-psionics-field" style={{
        "color": "rgb(87, 158, 182)"
      }}>null psionics field</a> or by a
power or spell that
neutralizes high-level powers or spells. Normal and magical fire, cold,
acid, and the like can still harm you.</p>
    <p>When your temporal acceleration expires, you resume acting
during your
current turn in the standard time frame. You are <a href="abilitiesAndConditions.html#shaken" style={{
        "color": "rgb(87, 158, 182)"
      }}>shaken</a> for 1 round
upon your return to the standard time frame.</p>
    <p>Splintered or partitioned minds within your own mind, such as
might be
in effect through the use of powers such as <a href="#schism" style={{
        "color": "rgb(87, 158, 182)"
      }}>schism</a>, are not temporally
speeded up, even if your second mind manifested this power (your
primary mind gains the benefit, while your second mind remains stuck in
the standard time frame).</p>
    <p>Manifesting this power is a swift action, like manifesting a
quickened
power, and it counts toward the normal limit of one quickened power per
round. You cannot manifest this power when it isn&rsquo;t your turn.</p>
    <p><b>Augment:</b> For every 4 additional power points you spend,
this power&rsquo;s
duration (in apparent time) increases by 1 round.</p>
    <h6><a id="thicken-skin"></a>Thicken Skin</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <b>Level:</b> Egoist 1, psychic warrior 1
    <b>Display:</b> Material and olfactory
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 10 min./level
    <b>Power Points:</b> 1
    <p>Your skin or natural armor thickens and spreads across your
body,
providing a +1 enhancement bonus to your Armor Class.</p>
    <p><b>Augment:</b> You can augment this power in one or both of
the following
ways.</p>
    <p>1. For every 3 additional power points you spend, the
enhancement bonus
increases by 1.</p>
    <p>2. If you spend 6 additional power points, you can manifest
this power
as a swift action.</p>
    <h6><a id="thought-shield"></a>Thought Shield</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <b>Level:</b> Psion/wilder 2, psychic warrior 2
    <b>Display:</b> Auditory
    <b>Manifesting Time:</b> 1 immediate action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 round
    <b>Power Points:</b> 3
    <p>You fortify your mind against intrusions, gaining power
resistance 13
against all mind-affecting powers.</p>
    <p>You can manifest this power instantly, quickly enough to gain
its
benefits in an emergency. Manifesting the power is an immediate action.
You can use this power even
when it&rsquo;s not your turn.</p>
    <p><b>Augment:</b> For every additional power point you spend,
this power&rsquo;s
duration increases by 1 round, and the power resistance it provides
increases by 1 point.</p>
    <h6><a id="time-hop"></a>Time Hop</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychoportation</span>
    <b>Level:</b> Psion/wilder 3
    <b>Display:</b> Auditory and visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Close (25 ft. + 5 ft./2 levels)
    <b>Targets:</b> One Medium or smaller creature, or one object
weighing 300 lb.
or less
    <b>Duration:</b> 1 round/level; see text
    <b>Saving Throw:</b> Will negates
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 5
    <p>The subject of the power hops forward in time 1 round for
every
manifester level you have. In effect, the subject seems to disappear in
a shimmer of silver energy, then reappear after the duration of this
power expires. The subject reappears in exactly the same orientation
and condition as before. From the subject&rsquo;s point of view, no
time has
passed at all.</p>
    <p>In each round of the power&rsquo;s duration, on what would
have been
the
subject&rsquo;s turn, it can attempt a DC 15 Wisdom check. Success
allows the
subject to return. The subject can act normally on its next turn after
this power ends.</p>
    <p>If the space from which the subject departed is occupied upon
his
return to the time stream, he appears in the closest unoccupied space,
still in his original orientation. Determine the closest space randomly
if necessary.</p>
    <p><b>Augment:</b> You can augment this power in one or both of
the following
ways.</p>
    <p>1. For every 2 additional power points you spend, you can
affect a
creature of one size category larger, or double the weight of an object
to be affected.</p>
    <p>2. For every 2 additional power points you spend, this power
can affect
an additional target. Any additional target cannot be more than 15 feet
from another target of the power.</p>
    <h6><a id="time-hop-mass"></a>Time Hop, Mass</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychoportation</span>
    <b>Level:</b> Nomad 8
    <b>Display:</b> Auditory and visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Close (25 ft. + 5 ft./2 levels)
    <b>Targets:</b> All willing creatures in range
    <b>Duration:</b> Up to 1 hour/level; see text
    <b>Power Points:</b> 15
    <p>As time hop, except you can affect any number of willing
subjects in
range, including yourself. You can choose which creatures are affected
by the power. The subjects hop forward in time a number of hours equal
to your manifester level, or some shorter number of hours; you decide
how many hours the mass time hop lasts when you manifest the power.</p>
    <p><b>Augment:</b> If you spend 6 additional power points, you
can manifest this
power as an immediate action.</p>
    <h6><a id="time-regression"></a>Time Regression</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychoportation</span>
    <b>Level:</b> Nomad 9
    <b>Display:</b> None
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> Instantaneous
    <b>Power Points:</b> 17, XP
    <p>You can regress apparent time 1 round into the past. In
effect, you
&ldquo;replay&rdquo; the previous round of activity. The power
regresses time to
the point along the time stream just prior to your previous turn,
undoing the effects of everyone else&rsquo;s actions in the meantime.
Once
you have used time regression, only you retain knowledge of what
happened during the round that is being replayed; however, you can
communicate that knowledge verbally to your companions, if desired.
During the round that you live through a second time, you can act on
knowledge you previously gained by already living through the immediate
future. In all likelihood, you&rsquo;ll probably not choose to manifest
time
regression during your second pass through the time stream, instead
taking completely new actions, but you pay the XP cost all the same.</p>
    <p><i>XP Cost:</i> 1,000 XP.</p>
    <h6><a id="timeless-body"></a>Timeless Body</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychoportation</span>
    <b>Level:</b> Psion/wilder 9
    <b>Display:</b> Material
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 round
    <b>Power Points:</b> 17
    <p>Your body ignores all harmful (and helpful) effects, beginning
when you
finish manifesting this power and ending at the end of your next turn.
While timeless body is in effect, you are invulnerable to all attacks
and powers.</p>
    <p>This power cannot be quickened.</p>
    <h6><a id="tongues-psionic"></a>Tongues, Psionic</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <b>Level:</b> Psion/wilder 2
    <b>Display:</b> None
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 10 min./level
    <b>Power Points:</b> 3
    <p>As the <a href="spellsTtoZ.html#tongues" style={{
        "color": "rgb(87, 158, 182)"
      }}>tongues</a> spell, except as noted
here. This power does
not enable
you to speak with creatures immune to mind-affecting powers.</p>
    <h6><a id="tornado-blast"></a>Tornado Blast</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychokinesis</span>
    <b>Level:</b> Kineticist 9
    <b>Display:</b> Auditory and visual; see text
    <b>Manifesting Time:</b> 1 round
    <b>Range:</b> Long (400 ft. + 40 ft./level)
    <b>Area:</b> 40-ft.-radius spread
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> Reflex half; see text
    <b>Power Resistance:</b> No
    <b>Power Points:</b> 17
    <p>You induce the formation of a slender vortex of fiercely
swirling air.
When you manifest it, a vortex of air visibly and audibly snakes out
from your outstretched hand.</p>
    <p>If you want to aim the vortex at a specific creature, you can
make a
ranged touch attack to strike the creature. If you succeed, direct
contact with the vortex deals 8d6 points of damage to the creature (no
save).</p>
    <p>Regardless of whether your ranged touch attack hits (and even
if you
forgo the attack), all creatures in the area (including the one
possibly damaged by direct contact) are picked up and violently dashed
about, dealing 17d6 points of damage to each one. Creatures that make a
successful Reflex save take half damage.</p>
    <p>After being dashed about, each creature that was affected
finds itself
situated in a new space 1d4 x 10 feet away from its original space in a
random direction. Walls and other barriers can restrict this
relocation; in such a case, the creature ends up adjacent to the
barrier.</p>
    <p><b>Augment:</b> For every additional power point you spend,
this power&rsquo;s area
damage (not the damage from direct contact dealt to a specific
creature) increases by 1d6 points (to a maximum of 24d6 points). For
each extra 2d6 points of damage, this power&rsquo;s save DC increases
by 1.</p>
    <h6><a id="touchsight"></a>Touchsight</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <b>Level:</b> Psion/wilder 3
    <b>Display:</b> Visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal; see text
    <b>Target:</b> You
    <b>Duration:</b> 1 min./level (D)
    <b>Power Points:</b> 5
    <p>You generate a subtle telekinetic field of mental contact,
allowing you
to &ldquo;feel&rdquo; your surroundings even in total darkness or when
your sight
would otherwise be obscured by your physical environment. Your
touchsight field emanates from you out to 60 feet. You ignore
invisibility, darkness, and concealment, though you must have line of
effect to a creature or an object to discern it. You do not need to
make <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
or <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
checks to notice creatures; you can detect and
pinpoint all creatures within 60 feet. In many circumstances, comparing
your regular senses to what you learn with touchsight is enough to tell
you the difference between visible, <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a>, hiding, and concealed
creatures.</p>
    <p><b>Augment:</b> For every 2 additional power points you spend,
the radius of
your touchsight field increases by 10 feet.</p>
    <h6><a id="tower-of-iron-will"></a>Tower of Iron Will</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <b>Level:</b> Psion/wilder 5
    <b>Display:</b> Auditory
    <b>Manifesting Time:</b> 1 immediate action
    <b>Range:</b> 10 ft.
    <b>Area:</b> 10-ft.-radius emanation centered on you
    <b>Duration:</b> 1 round
    <b>Saving Throw:</b> None (harmless)
    <b>Power Resistance:</b> Yes (harmless)
    <b>Power Points:</b> 9
    <p>You generate a bastion of thought so strong that it offers
protection
to you and everyone around you, improving the self-control of all. You
and all creatures in the power&rsquo;s area gain power resistance 19
against
all mind-affecting powers.</p>
    <p>You can manifest this power instantly, quickly enough to gain
its
benefits in an emergency. Manifesting the power is an immediate action.
You can use this power even
when it is not your turn.</p>
    <p><b>Augment:</b> For every additional power point you spend,
this power&rsquo;s
duration increases by 1 round and the power resistance it provides
increases by 1 point.</p>
    <h6><a id="trace-teleport"></a>Trace Teleport</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience</span>
    <b>Level:</b> Psion/wilder 4
    <b>Display:</b> Visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Close (25 ft. + 5 ft./2 levels)
    <b>Area:</b> Spread with a radius of 25 ft. + 5 ft./2 levels,
centered on you
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> None
    <b>Power Resistance:</b> No
    <b>Power Points:</b> 7
    <p>As <a href="psionicPowersDtoF.html#detect-teleportation" style={{
        "color": "rgb(87, 158, 182)"
      }}>detect teleportation</a>, except you
can trace the destination
of any
psionic or magical teleportation made by others within this
power&rsquo;s
area within the last minute.</p>
    <p>You know the direction and distance the individuals traveled
and could
teleport to the location yourself if you so desired (and if you know
the <a href="#teleport-psionic" style={{
        "color": "rgb(87, 158, 182)"
      }}>psionic
teleport</a> power), as if you had &ldquo;seen casually&rdquo; the
location. This power does not grant you any information on the
conditions at the other end of the trace beyond the mental coordinates
of the location.</p>
    <p><b>Augment:</b> If you spend 2 additional power points, this
power&rsquo;s range
increases to Medium (100 ft. + 10 ft./level).</p>
    <h6><a id="true-creation"></a>True Creation</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Metacreativity (Creation)</span>
    <b>Level:</b> Shaper 9
    <b>Duration:</b> Instantaneous
    <b>Power Points:</b> 17, XP
    <p>As <a href="psionicPowersGtoP.html#major-creation-psionic" style={{
        "color": "rgb(87, 158, 182)"
      }}>psionic major creation</a>, except
items created are enduring
and cannot
be negated by dispelling magic or negating powers. For all intents and
purposes, these items are completely real.</p>
    <p><i>XP Cost:</i> 1/5 of the item&rsquo;s gold piece value, or a
minimum of 1 XP.</p>
    <h6><a id="true-metabolism"></a>True Metabolism</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <b>Level:</b> Psion/wilder 8
    <b>Display:</b> Material
    <b>Manifesting Time:</b> 1 round
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 min./level
    <b>Power Points:</b> 15
    <p>You are difficult to kill while this power persists. You
automatically
heal damage at the rate of 10 hit points per round.</p>
    <p>This power is not effective against damage from starvation,
thirst, or
suffocation. Also, attack forms that don&rsquo;t deal hit point damage
(for
example, most poisons) ignore true metabolism. You can also use this
power to regrow lost portions of your body and to reattach severed
limbs or body parts, if you do nothing but concentrate on regrowing the
lost body part or reattaching the severed limb for the duration of the
power. You do not gain the benefits described earlier when you manifest
true metabolism for this purpose. You must have a Constitution score to
gain any of this power&rsquo;s benefits.</p>
    <h6><a id="true-seeing-psionic"></a>True Seeing, Psionic</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience</span>
    <b>Level:</b> Psion/wilder 5
    <b>Display:</b> Visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 min./level
    <b>Power Points:</b> 9
    <p>As the <a href="spellsTtoZ.html#true-seeing" style={{
        "color": "rgb(87, 158, 182)"
      }}>true seeing</a> spell, except as
noted here. </p>
    <h6><a id="truevenom"></a>Truevenom</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <b>Level:</b> Psychic warrior 4
    <b>Display:</b> Material; see text
    <b>Manifesting Time:</b> 1 swift action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 min./level or until discharged
    <b>Saving Throw:</b> None and Fortitude negates; see text
    <b>Power Points:</b> 7
    <p>If you have a claw attack (either from an actual natural
weapon or from
an effect such as <a href="psionicPowersAtoC.html#claws-of-the-beast" style={{
        "color": "rgb(87, 158, 182)"
      }}>claws of the beast</a>), you can use
this power to
produce a horrible poison that coats one of your claws. On your next
successful melee attack with the claw during the power&rsquo;s
duration, the
poison deals 1d8 points of Constitution <a href="abilitiesAndConditions.html#ability-damaged" style={{
        "color": "rgb(87, 158, 182)"
      }}>damage</a> immediately and another
1d8 points of Constitution damage 1 minute later. The target of your
attack can negate each instance of damage with a Fortitude save.</p>
    <h6><a id="truevenom-weapon"></a>Truevenom Weapon</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism (Creation)</span>
    <b>Level:</b> Psychic warrior 4
    <b>Range:</b> Touch
    <b>Target:</b> Weapon touched
    <b>Power Points:</b> 7
    <p>As <a href="#truevenom" style={{
        "color": "rgb(87, 158, 182)"
      }}>truevenom</a>,
except your weapon gains the poison coating as
long as it
remains in your grip, until the effect is discharged, or until the
duration expires, whichever occurs first.</p>
    <h6><a id="ubiquitous-vision"></a>Ubiquitous Vision</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience</span>
    <b>Level:</b> Psion/wilder 3, psychic warrior 3
    <b>Display:</b> Visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 10 min./level (D)
    <b>Power Points:</b> 5
    <p>You have metaphoric &ldquo;eyes in the back of your
head,&rdquo; and on
the sides
and top as well, granting you benefits in specific situations. In
effect, you have a 360-degree sphere of sight, allowing you a perfect
view of creatures that might otherwise flank you. Thus, flanking
opponents gain no bonus on their attack rolls, and rogues are denied
their sneak attack ability because you do not lose your Dexterity bonus
(but they may still sneak attack you if you are caught flat-footed).
Your <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
and <a href="skillsAll.html#search" style={{
        "color": "rgb(87, 158, 182)"
      }}>Search</a>
checks gain a +4 enhancement bonus. Concurrently,
you take a -4 penalty on saves against all gaze attacks during the
power&rsquo;s duration.</p>
    <h6><a id="ultrablast"></a>Ultrablast</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <b>Level:</b> Psion/wilder 7
    <b>Display:</b> Auditory; see text
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> 15 ft.
    <b>Area:</b> 15-ft.-radius spread centered on you
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> Will half
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 13
    <p>You &ldquo;grumble&rdquo; psychically (which both psionic and
nonpsionic
creatures
can detect), then release a horrid shriek from your subconscious that
disrupts the brains of all enemies in the power&rsquo;s area, dealing
13d6
points of damage to each enemy.</p>
    <p><b>Augment:</b> For every additional power point you spend,
this power&rsquo;s
damage increases by 1d6 points. </p>
    <h6><a id="vampiric-blade"></a>Vampiric Blade</h6>
Psychometabolism
    <b>Level:</b> Psychic warrior 3
    <b>Display:</b> Material and visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> 0 ft.
    <b>Target:</b> One weapon you hold
    <b>Duration:</b> 1 round/level
    <b>Power Points:</b> 5
    <p>As <a href="psionicPowersAtoC.html#claws-of-the-vampire" style={{
        "color": "rgb(87, 158, 182)"
      }}>claws of the vampire</a>, except
your weapon is affected as
long as it
remains in your grip or until this power&rsquo;s duration expires.</p>
    <h6><a id="vigor"></a>Vigor</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <b>Level:</b> Psion/wilder 1, psychic warrior 1
    <b>Display:</b> Material and olfactory
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 min./level
    <b>Power Points:</b> 1
    <p>You suffuse yourself with power, gaining 5 temporary hit
points. Using
this power again when an earlier manifestation has not expired merely
replaces the older temporary hit points (if any remain) with the newer
ones.</p>
    <p><b>Augment:</b> For every additional power point you spend,
the number of
temporary hit points you gain increases by 5.</p>
    <h6><a id="wall-of-ectoplasm"></a>Wall of Ectoplasm</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Metacreativity (Creation)</span>
    <b>Level:</b> Psion/wilder 4
    <b>Display:</b> Visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Close (25 ft. + 5 ft./2 levels)
    <b>Effect:</b> Wall whose area is up to one 10-ft. square/level
or a sphere or
hemisphere with a radius of up to 1 ft./level
    <b>Duration:</b> 1 min./level (D)
    <b>Saving Throw:</b> None
    <b>Power Resistance:</b> No
    <b>Power Points:</b> 7
    <p>You fashion a roiling wall of ectoplasm, imbuing it with
solidity. The
wall cannot move once it is formed. It is 1 inch thick per four
manifester levels and occupies up to one 10-foot square per level. Each
10-foot square of the wall has 10 hit points per inch of thickness and
hardness 5. A section of the wall whose hit points drop to 0 is
breached. If a creature tries to break through the wall, the DC for the
Strength check is 15 + 2 per inch of thickness.</p>
    <p>The wall of ectoplasm is susceptible to <a href="psionicPowersDtoF.html#dispel-psionics" style={{
        "color": "rgb(87, 158, 182)"
      }}>dispel psionics</a>, but
it gains a
+4 bonus on any check to determine whether the wall is negated. Spells,
powers, and breath weapons cannot pass through the wall in either
direction (though they could damage it). It blocks ethereal creatures
as well as material creatures (though ethereal creatures can usually
get around the wall by floating under or over it through material
floors and ceilings). The wall is opaque, so neither vision nor gaze
attacks operate through it. The wall does not block psychoportive
travel, such as that provided by the <a href="#teleport-psionic" style={{
        "color": "rgb(87, 158, 182)"
      }}>psionic teleport</a> power.</p>
    <p>You can form the wall into a flat, vertical plane whose area
is up to
one 10-foot square per level or into a sphere or hemisphere with a
radius of up to 1 foot per level.</p>
    <p>The wall of ectoplasm must be continuous and unbroken when
manifested.
If its surface is interrupted by any object or creature, the power
fails.</p>
    <h6><a id="wall-walker"></a>Wall Walker</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychoportation</span>
    <b>Level:</b> Psychic warrior 2
    <b>Display:</b> Material
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 min./level
    <b>Power Points:</b> 3
    <p>You can walk on vertical surfaces or even traverse ceilings
(you need
not make <a href="skillsAll.html#climb" style={{
        "color": "rgb(87, 158, 182)"
      }}>Climb</a> checks to traverse these
surfaces). Because of the need
to keep at least one foot in contact with the wall or ceiling at all
times, you cannot jump or use the run action, and you can move at only
half speed.</p>
    <p>You retain your Dexterity bonus to Armor Class, if any, and
opponents
gain no special bonuses against you. </p>
    <h6><a id="weapon-of-energy"></a>Weapon of Energy</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychokinesis [see text]</span>
    <b>Level:</b> Psychic warrior 4
    <b>Display:</b> Visual
    <b>Range:</b> Touch
    <b>Target:</b> Weapon touched
    <b>Duration:</b> 1 round/level
    <b>Saving Throw:</b> Fortitude negates (object, harmless)
    <b>Power Resistance:</b> None
    <b>Power Points:</b> 7
    <p>As <a href="psionicPowersAtoC.html#claw-of-energy" style={{
        "color": "rgb(87, 158, 182)"
      }}>claw of energy</a>, except this
power can be manifested on a
touched
weapon. This power&rsquo;s subtype is the same as the type of energy
infused
in the touched weapon.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      